
import { ProfileMenuFields } from "@/models/utils/profile-menu-fields.interface";
import { authenticate } from "@/services/api/authenticate.service";
import store from "@/store";
import AuthModule from "@/store/modules/auth.module";
import OverlayModule from "@/store/modules/overlay.module";
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { SnackbarModule } from "vuelando";
import { getModule } from "vuex-module-decorators";

const authModule = getModule(AuthModule, store);

const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class ProfileMenu extends Vue {
  @Prop({ required: true, default: null, type: String })
  private username: string;

  @Prop({ required: false, default: null, type: Number })
  private selectedPrimary: number;

  @Prop({ required: false, default: null, type: Number })
  private selectedSecondary: number;

  @PropSync("profileMenuFieldsPrincipal", {
    required: true,
    default: null,
    type: Array,
  })
  private ProfileMenuFieldsPrincipal: ProfileMenuFields[];

  @PropSync("profileMenuFieldsSecondary", {
    required: false,
    default: null,
    type: Array,
  })
  private ProfileMenuFieldsSecondary: ProfileMenuFields[];

  private changePage(key: string): void {
    this.ProfileMenuFieldsPrincipal.forEach((x) => {
      if (key == x.key) {
        x.active = true;
      } else {
        x.active = false;
      }
    });
    if (this.ProfileMenuFieldsSecondary != null) {
      this.ProfileMenuFieldsSecondary.forEach((y) => {
        if (key == y.key) {
          y.active = true;
        } else {
          y.active = false;
        }
      });
    }
    this.$forceUpdate();
    this.$emit("click", key);
  }

  public clickEmit(key: string): void {
    this.ProfileMenuFieldsPrincipal.forEach((x) => {
      if (key == x.key) {
        x.active = true;
      } else {
        x.active = false;
      }
    });
    if (this.ProfileMenuFieldsSecondary != null) {
      this.ProfileMenuFieldsSecondary.forEach((y) => {
        if (key == y.key) {
          y.active = true;
        } else {
          y.active = false;
        }
      });
    }
    this.$forceUpdate();
  }

  private async logout(): Promise<void> {
    await authenticate.logout();
    this.$router.push({
      name: "Logout",
    });
  }
}
