
import { OrderingSubjectView } from "@/models/entities/ordering-subject-view.interface";
import store from "@/store";
import AuthModule from "@/store/modules/auth.module";
import OverlayModule from "@/store/modules/overlay.module";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { orderingSubjectService } from "@/services/api/ordering-subject.service";
import PageTitleModule from "@/store/modules/pageTitle.module";
import ProfileMenu from "./Components/ProfileMenu.vue";
import { BreadCrumb } from "@/models/utils/breadcrumb.interface";
import { ProfileMenuFields } from "@/models/utils/profile-menu-fields.interface";
import { SubjectAndRole } from "@/models/entities/subject-role.interface";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);
const pageTitleModule = getModule(PageTitleModule, store);

const ModifyOrderingSubjects = () =>
  import(
    /* webpackChunkName: "ModifyOrderingSubjects" */ "./Components/ModifyOrderingSubjects.vue"
  );

const Overview = () =>
  import(/* webpackChunkName: "Overview" */ "./Components/Overview.vue");

const PersonalData = () =>
  import(
    /* webpackChunkName: "PersonalData" */ "./Components/PersonalData.vue"
  );

const ModifyPassword = () =>
  import(/* webpackChunkName: "Password" */ "./Components/Password.vue");

const OrderingSubjectList = () =>
  import("./Components/OrderingSubjectList.vue");

const Payments = () => import("./Components/Payments.vue");

// const Settings = () =>
//   import(/* webpackChunkName: "Settings" */ "./Components/Settings.vue");

@Component({
  components: {
    "modify-ordering-subject": ModifyOrderingSubjects,
    //  settings: Settings,
    "profile-menu": ProfileMenu,
    overview: Overview,
    "personal-data": PersonalData,
    "modify-password": ModifyPassword,
    "ordering-subject-list": OrderingSubjectList,
    payments: Payments,
  },
})
export default class Account extends Vue {
  @Prop({ type: String, default: "false", required: false })
  private propOrderingSubject: string;
  private newOrderingSubject: string;
  private orderingSubjectId = 0;
  private orderingSubjectView: OrderingSubjectView[] = [];
  private componentKey = "";
  private previousContext = "";
  private colsSpace = 6;
  private customClass = "content-col px-8 py-6";
  private currentValue = "";

  private subjectAndRole: SubjectAndRole = {
    roleDescription: "",
    totalSubjects: 1,
  };

  public breadcrumb: BreadCrumb[] = [
    {
      text: this.$tc("breadcrumb.Account"),
      disabled: true,
      href: "/account",
      current: true,
    },
  ];

  private get profileMenuFieldsPrincipal(): ProfileMenuFields[] {
    return [
      {
        fieldName: this.$tc("account.Overview"),
        icon: "chart",
        key: "overview",
        active: !this.componentKey || this.componentKey == "overview",
      },
      {
        fieldName: this.$tc("account.PersonalData"),
        icon: "promoter",
        key: "personalData",
        active: this.componentKey == "personalData",
      },
      {
        fieldName: this.$tc("account.Password"),
        icon: "key",
        key: "modifyPassword",
        active: this.componentKey == "modifyPassword",
      },
      {
        fieldName: this.$tc("account.Payment"),
        icon: "box",
        key: "payments",
        active: this.componentKey == "payments",
      },
      // {
      //   fieldName: this.$tc("account.Settings"),
      //   icon: "cost-note",
      //   key: "settings",
      //   active: this.componentKey == "settings",
      // },
    ];
  }

  private get profileMenuFieldsSecondary(): ProfileMenuFields[] {
    return [
      {
        fieldName: this.$tc("account.OrderingList"),
        icon: "clipboard",
        key: "orderingSubjectList",
        active: false,
      },
    ];
  }

  //   @Watch("$route.params.context")
  //   async contextChanged(): Promise<void> {
  //     if (this.$route.params.context == "settings") await this.reloadContext();
  //   }

  @Watch("$route.params.context")
  async contextChanged(): Promise<void> {
    this.componentKey = this.$route.params.context;
    if (this.$route.params.context != "modifyPassword") {
      await this.reloadContext();
    }
  }

  async mounted(): Promise<void> {
    await this.reloadContext();
    this.subjectAndRole = (
      await orderingSubjectService.getRoleAndTotSubject()
    ).data;
  }

  async reloadContext(): Promise<void> {
    let context = this.$route.params.context;
    this.currentValue = context;
    if (!context) {
      this.$router.push({
        name: "account",
        params: {
          context: "overview",
        },
      });
      context = "overview";
    }
    pageTitleModule.setBreadCrumb(this.breadcrumb);
    this.newOrderingSubject = this.propOrderingSubject;
    await this.getOrderingSubjectView(0);
    if (this.newOrderingSubject == "false") {
      this.changePage(context);
    } else {
      this.changePage(context);
      this.profileMenuFieldsSecondary[0].active = true;
      this.profileMenuFieldsPrincipal[0].active = false;
    }
  }

  get username(): string {
    return authModule.userName ? authModule.userName : "";
  }

  private async getOrderingSubjectView(id: number) {
    this.orderingSubjectView = (
      await orderingSubjectService.readAll(authModule.userID)
    ).data.map((x) => {
      x.active = false;
      return x;
    });
    this.orderingSubjectId = id;
    if (this.orderingSubjectView.length > 0) {
      if (id == 0) {
        this.orderingSubjectView[0].active = true;
        this.changeOrderingSubjectId(
          this.orderingSubjectView[0].orderingSubjectID
        );
      } else {
        this.orderingSubjectView.forEach((y) => {
          if (y.orderingSubjectID == id) {
            y.active = true;
          } else {
            y.active = false;
          }
        });
      }
    }
  }

  private async changePage(value: string): Promise<void> {
    this.previousContext = this.componentKey;
    this.componentKey = value;
    if (this.newOrderingSubject == "true") {
      this.orderingSubjectId = -1;
      this.newOrderingSubject = "false";
    } else {
      if (this.orderingSubjectView.length > 0) {
        this.orderingSubjectId = this.orderingSubjectView[0].orderingSubjectID;
        this.orderingSubjectView[0].active = true;
      }
    }
    switch (value) {
      case "overview":
        if (this.$refs.profileMenu)
          (this.$refs.profileMenu as ProfileMenu).clickEmit(value);
        this.colsSpace = 10;
        this.customClass = "content-col px-8 py-6 customBackground";
        break;
      case "personalData":
        if (this.$refs.profileMenu)
          (this.$refs.profileMenu as ProfileMenu).clickEmit(value);
        this.colsSpace = 7;
        this.customClass = "content-col px-8 py-6";
        break;
      case "modifyPassword":
        pageTitleModule.setBreadCrumb(this.breadcrumb);
        if (this.$refs.profileMenu)
          (this.$refs.profileMenu as ProfileMenu).clickEmit(value);
        this.colsSpace = 7;
        this.customClass = "content-col px-8 py-6";
        break;
      case "orderingSubjectList":
        if (this.$refs.profileMenu)
          (this.$refs.profileMenu as ProfileMenu).clickEmit(value);
        this.colsSpace = 7;
        this.customClass = "content-col px-8 py-6";
        break;
      case "payments":
        if (this.$refs.profileMenu)
          (this.$refs.profileMenu as ProfileMenu).clickEmit(value);
        this.colsSpace = 10;
        this.customClass = "content-col px-8 py-6";
        break;
      // case "settings":
      //   this.colsSpace = 7;
      //   this.customClass = "content-col px-8 py-6";
      //   break;
    }
  }

  private async changePageRoute(value: string): Promise<void> {
    if (value != this.currentValue) {
      this.currentValue = value;
      this.$router.push({
        name: "account",
        params: {
          context: value,
        },
      });
      this.changePage(value);
    }
  }

  private async backToPage(): Promise<void> {
    this.$router.push({
      name: "account",
      params: {
        context: this.previousContext,
      },
    });
    this.currentValue = this.previousContext;
    this.changePage(this.previousContext);
  }

  private newOrderingSub() {
    this.orderingSubjectId = -1;
  }

  private changeOrderingSubjectId(id: number) {
    this.orderingSubjectId = id;
    this.orderingSubjectView.forEach((x) => {
      if (id == x.orderingSubjectID) {
        x.active = true;
      } else {
        x.active = false;
      }
    });
  }

  private cancelModifyOrderingSubject(): void {
    if (this.orderingSubjectView.length > 0) {
      this.orderingSubjectId = this.orderingSubjectView[0].orderingSubjectID;
      this.orderingSubjectView[0].active = true;
    } else {
      this.orderingSubjectId = -1;
    }
  }
  private async saveModifyOrderingSubject(id: number): Promise<void> {
    this.orderingSubjectId = id;
    await this.getOrderingSubjectView(id);
  }

  private async deleteOrderingSubjects(): Promise<void> {
    await this.getOrderingSubjectView(0);
  }
}
